import { User } from './models'

class Storage {
  getToken = () => localStorage.getItem('jwt')
  saveToken = (token: string) => localStorage.setItem('jwt', token)
  deleteToken = () => localStorage.removeItem('jwt')

  getUser = () => JSON.parse(localStorage.getItem('user') ?? 'null') as User
  saveUser = (user: User) => localStorage.setItem('user', JSON.stringify(user))
  deleteUser = () => localStorage.removeItem('user')
}
export const storage = new Storage()
