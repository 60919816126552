import React, { useState, useMemo, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Column, Ordering, Paginator, Subtitle, Table } from '@duckma/react-ds'

import { useRequest } from '../../../hooks/useRequest'
import { api } from '../../../data/api'
import { User, UserMessage } from '../../../data/models'
import { dateTimeFormatter } from '../../../utils/formatters'
import { SkeletonTable } from '../../../components/SkeletonTable'

export const CallsTab: React.FC<{ user: User }> = ({ user }) => {
  const [getMessages, { status, data }] = useRequest(api.getUsersMessages)

  const [ordering, setOrdering] = useState<Ordering<UserMessage>>({
    key: 'created_at',
    direction: 'asc',
  })
  const [page, setPage] = useState<number>(0)

  const getMessagesWithParams = useCallback(
    () =>
      getMessages({
        order_by: ordering.key,
        order_direction: ordering.direction,
        page,
        page_size: 10,
        id: user.id,
      }),
    [getMessages, ordering.direction, ordering.key, page, user.id]
  )

  useEffect(() => {
    getMessagesWithParams()
  }, [getMessagesWithParams])

  const columns = useMemo(
    () =>
      [
        {
          key: 'created_at',
          name: 'Data ora chiamata',
          orderable: true,
          render: ({ created_at }) => dateTimeFormatter(created_at),
        },
        {
          key: 'sender_name',
          name: 'Nominativo',
          orderable: true,
          render: ({ sender_name, sender_phone_number }) => sender_name ?? sender_phone_number,
        },
        { key: 'body', name: 'Messaggio', orderable: true },
        {
          key: 'appointment',
          name: 'Appuntamento',
          orderable: true,
          render: ({ appointment }) => (appointment ? dateTimeFormatter(appointment.from) : ''),
        },
        { key: 'duration', name: 'Durata call', orderable: true },
      ] as Column<UserMessage>[],
    []
  )

  return (
    <Container>
      {status !== 'loading' ? (
        <Table
          columns={columns}
          records={data ? data.items : []}
          order={ordering}
          onOrder={setOrdering}
        />
      ) : (
        <SkeletonTable />
      )}
      {status === 'success' && data && data.items.length > 0 && (
        <Paginator
          style={{ marginTop: '75px', marginBottom: '25px' }}
          currentPage={page}
          totalPages={data.pagination.total_pages}
          onPageSelect={setPage}
        />
      )}
      {status === 'success' && data && data.items.length === 0 && (
        <Subtitle text="Nessun utente trovato" color="gray100" />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
`
