import React from 'react'
import Skeleton from 'react-loading-skeleton'
import _ from 'lodash'

type Props = {
  items: number
}

export const SkeletonProductCard = ({ items }: Props) => {
  return (
    <>
      {' '}
      {_.times(items, (i) => (
        <Skeleton key={i} width="90%" height="70px" style={{ marginBottom: '20px' }} />
      ))}{' '}
    </>
  )
}
