import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { api } from '../../data/api'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Table, Ordering, Tag, ColorName, Button, Paginator } from '@duckma/react-ds'
import { useRequest } from '../../hooks/useRequest'
import { User, Role } from '../../data/models'
import { Column, Subtitle } from '@duckma/react-ds'
import { SearchField } from '../../components/SearchField'
import { SkeletonTable } from '../../components/SkeletonTable'

const tags: { [key in Role]: { color: ColorName; label: string } } = {
  user: { color: 'primary100', label: 'Mysecretary' },
  operator: { color: 'primary100', label: 'Operatore centralino' },
  admin: { color: 'primary100', label: 'Admin' },
  super_admin: { color: 'primary100', label: 'Super Admin' },
}

export const UsersPage = () => {
  const history = useNavigate()
  const [getUsers, { status, data }] = useRequest(api.getUsers)

  const [ordering, setOrdering] = useState<Ordering<User>>({
    key: 'first_name',
    direction: 'asc',
  })
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [page, setPage] = useState<number>(0)

  const getUsersWithParams = useCallback(
    () =>
      getUsers({
        order_by: ordering.key,
        order_direction: ordering.direction,
        search_text: search,
        page,
        page_size: 10,
      }),
    [getUsers, ordering, page, search]
  )

  useEffect(() => {
    getUsersWithParams()
  }, [getUsersWithParams])

  const columns = useMemo(
    () =>
      [
        { key: 'first_name', name: 'Nome', orderable: true },
        { key: 'last_name', name: 'Cognome', orderable: true },
        {
          key: 'role',
          name: 'Ruolo',
          orderable: true,
          render: (user) => <Tag text={tags[user.role].label} color={tags[user.role].color} />,
        },
        {
          key: 'subscription_status',
          name: 'Sottoscrizione',
          orderable: true,
          render: (user) =>
            user.role === 'user' ? (
              <Tag
                text={user.subscription?.is_active ? 'Attiva' : 'Scaduta'}
                color={user.subscription?.is_active ? 'success100' : 'danger100'}
              />
            ) : (
              <div />
            ),
        },
        { key: 'email', name: 'Email', orderable: true },
        {
          key: 'actions',
          name: 'Azioni',
          orderable: false,
          render: (user) => (
            <Actions>
              <Button
                size="icon"
                iconLeft="pen-alt"
                color="primary"
                radius={24}
                onClick={() => {
                  history('/users/' + user.id)
                }}
              />
            </Actions>
          ),
        },
      ] as Column<User>[],
    [history]
  )

  return (
    <Container>
      <SearchField
        style={{ alignSelf: 'flex-end', marginBottom: '35px' }}
        onSearch={(search) => {
          if (page !== 0) {
            setPage(0)
          }
          setSearch(search)
        }}
      />
      {status !== 'loading' ? (
        <Table
          columns={columns}
          records={data ? data.items : []}
          order={ordering}
          onOrder={setOrdering}
        />
      ) : (
        <SkeletonTable />
      )}
      {status === 'success' && data && data.items.length > 0 && (
        <Paginator
          style={{ marginTop: '75px', marginBottom: '75px' }}
          currentPage={page}
          totalPages={data.pagination.total_pages}
          onPageSelect={setPage}
        />
      )}
      {status === 'success' && data && data.items.length === 0 && (
        <Subtitle text="Nessun utente trovato" color="gray100" />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  * {
    margin-right: 20px;
  }
`
