export type Role = 'user' | 'admin' | 'super_admin' | 'operator'
export interface User {
  id: string
  email: string
  role: Role
  first_name: string
  last_name: string
  title: UserTitle
  stripe_customer_id?: string | null
  subscription: {
    id: string
    is_active: boolean
    expires_at: Date
    plan: 'free' | 'base' | 'premium'
  }
  phone_numbers?: { number: string; type: string }[]
  created_at: string
}

export enum Day {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export interface Billing {
  type: BillingType
  first_name: string
  last_name: string
  birthday: Date | string | null
  tax_code: string
  address: string
  zip_code: string
  city: string
  state: string
  country: string
  business_name: string
  vat_number: string
  sdi: string
  pec: string
}

export interface CheckoutResponse {
  id: string
  object: string
  allow_promotion_codes: boolean | null
  amount_subtotal: number
  amount_total: number
  billing_address_collection: string | null
  cancel_url: string
  client_reference_id: string | null
  currency: string
  customer: string | null
  customer_details: string | null
  customer_email: string | null
  livemode: boolean
  locale: string | null
  metadata: {
    userId: string
    appliedVoucherId: string
    priceId: string
  }
  mode: string
  payment_intent: string | null
  payment_method_options: string | null
  payment_method_types: string[]
  payment_status: string
  setup_intent: string | null
  shipping: string | null
  shipping_address_collection: string | null
  submit_type: string | null
  subscription: string | null
  success_url: string
  total_details: {
    amount_discount: number
    amount_shipping: number
    amount_tax: number
  }
}

export enum UserTitle {
  NONE = 'none',
  MISTER = 'mister',
  MISSUS = 'missus',
  DOCTOR_M = 'doctor_m',
  ENGINEER = 'engineer',
  DOCTOR_F = 'doctor_f',
  MISS = 'miss',
  ACCOUNTANT = 'accountant',
  ADMINISTRATOR = 'administrator',
  ATTORNEY = 'attorney',
  SURVEYOR = 'surveyor',
  DIRECTOR = 'director',
  ARCHITECT = 'architect',
  NOTARY = 'notary',
}

export enum BillingType {
  PERSONAL = 'personal',
  COMPANY = 'company',
}

export interface AvailabilitySlot {
  id: string
  day: Day
  startDate: Date
  endDate: Date
}

export interface Products {
  subscription: SubscriptionProduct[]
  oneshot: OneshotProduct[]
}

export interface Product {
  id: string
  price_id: string
  name: string
  plan?: string
  description: string
  price_cents: number
  duration_months?: number
  talk_time_seconds?: number
  currency: string
}

export interface SubscriptionProduct extends Product {
  duration_months: number
  plan: string
}

export interface OneshotProduct extends Product {
  talk_time_seconds: number
}

export interface UserServices {
  total_used_seconds: number
  activations: number
  handled_calls_last_instance?: number
  handled_calls_today?: number
  handled_calls?: number
  lost_calls?: number
  call_seconds_used?: number
  call_seconds_max?: number
  last_activity?: string
}

export interface UserMessage {
  body: string
  duration: string
  created_at: string
  id: string
  sender_name?: string
  sender_phone_number: string
  appointment?: { from: string; to: string }
}

export interface UserContact {
  phone_number: string
  name: string
}

export interface EventType {
  id?: string
  name: string
  color: string
  created_at?: string
  updated_at?: string
}

export interface Period {
  from: string
  to: string
}
export type EventStatus = 'requested' | 'accepted' | 'declined' | 'delete_requested'
export interface Event {
  id?: string
  title: string
  period: Period
  type: EventType
  permissions?: 'public' | 'private'
  status?: EventStatus
  owner?: User | null
  created_at?: string
}

export interface CallDetail {
  id: string
  caller_phone_number: string
  caller_name: string
  called_phone_number: string
  called_id: string
  called_name: string
  is_premium: boolean
  message: CallEnd
  instructions: string
}

export interface CallEnd {
  id: string
  body: string
  created_at: string
  sender_phone_number: string
  sender_name: string
  appointment?: {
    id?: string
    from: Date
    to: Date
  }
}

export type WidgetType = 'digits' | 'table'
export type WidgetPeriod = 'day' | 'week' | 'overall'
export type DigitsWidget = {
  id: string
  type: 'digits'
  props: {
    title: string
    period: WidgetPeriod
  }
  items: {
    description: string
    fraction?: string | null
    value?: string | null
  }[]
}
export type TableWidget = {
  id: string
  type: 'table'
  props: { title: string; period: WidgetPeriod; columns: string[] }
  items: {
    columns: { [column: string]: string }
  }[]
}

export type Dashboard = (DigitsWidget | TableWidget)[]
