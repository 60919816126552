import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/it'

type Props = {
  title?: string
  subscription: {
    id: string
    is_active: boolean
    expires_at: Date
    plan: 'free' | 'base' | 'premium'
  }
}

export const SubscriptionBox = ({ title, subscription }: Props) => {
  return (
    <SubscriptionBoxContainer>
      {title && <SubscriptionBoxTitle>{title} </SubscriptionBoxTitle>}
      <div style={{ display: 'flex' }}>
        <SubscriptionBoxText>
          Abbonamento {subscription.plan !== 'free' ? subscription.plan : ''}
        </SubscriptionBoxText>
        <SubscriptionBoxText
          style={{
            color: moment(subscription.expires_at).isSameOrAfter(moment())
              ? moment(subscription.expires_at).isSameOrAfter(moment().add(5, 'days'))
                ? '#94CF63'
                : '#F0903E'
              : '#E82929',
            marginLeft: 'auto',
          }}
        >
          {moment(subscription.expires_at).isSameOrAfter(moment())
            ? moment(subscription.expires_at).isSameOrAfter(moment().add(5, 'days'))
              ? 'Attivo'
              : 'In scadenza'
            : 'Scaduto'}
        </SubscriptionBoxText>
      </div>
      <SubscriptionBoxSubText>
        {subscription.expires_at
          ? moment(subscription.expires_at).isSameOrAfter(moment())
            ? `Scadenza ${moment(subscription.expires_at).format('DD MMMM YYYY')}`
            : `Scaduto ${moment(subscription.expires_at).format('DD MMMM YYYY')}`
          : ''}
      </SubscriptionBoxSubText>
    </SubscriptionBoxContainer>
  )
}

const SubscriptionBoxContainer = styled.div`
  width: calc(100% - 40px);
  padding: 20px;
  margin-top: 50px;
  background-color: #f7f7f7;
  border-radius: 20px;
`
const SubscriptionBoxTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  font-family: 'Open sans', sans-serif;
  color: #3b7b95;
`

const SubscriptionBoxText = styled.p`
  font-size: 16px;
  font-family: 'Open sans', sans-serif;
  color: #222;
  margin-bottom: 0;
`

const SubscriptionBoxSubText = styled.p`
  font-size: 16px;
  font-family: 'Open sans', sans-serif;
  color: '#222';
  margin-bottom: 0;
  font-size: 13px;
  margin-top: 4px;
`
