import {
  User,
  UserServices,
  Event,
  Dashboard,
  UserMessage,
  UserContact,
  CallDetail,
  EventStatus,
  CallEnd,
  AvailabilitySlot,
  Period,
  Products,
  Billing,
  Product,
  CheckoutResponse,
} from './models'
import { storage } from './storage'
import _ from 'lodash'

class API {
  private request = async <T>(
    method: string,
    path: string,
    body?: {},
    params?: { [key: string]: string | string[] },
    useToken: boolean = true
  ) => {
    let uri = process.env.REACT_APP_BASE_URL + path
    if (_.keys(params).length > 0) {
      uri +=
        '?' +
        _.map(params, (value, key) =>
          Array.isArray(value) ? value.map((v) => `${key}=${v}`).join('&') : `${key}=${value}`
        ).join('&')
    }
    return fetch(uri, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: this.getHeaders(useToken),
    }).then((res) => this.handleResponse<T>(res))
  }

  private handleResponse = <T>(response: Response) => {
    if (response.status !== 200) {
      console.error(`Error while sending request. Code: ${response.status}`)
    }
    if (response.status === 401) {
      this.clearSession()
      window.location.reload()
    }
    return response.json().then((json: { data: T | null; error: null | { code: string } }) => {
      if (response.status !== 200) {
        throw new Error(json.error!.code ?? 'invalid_server_response')
      }
      return json.data!
    })
  }

  private getHeaders = (useToken: boolean) => {
    const token = storage.getToken()
    return {
      ...(useToken && token ? { Authorization: `Bearer ${token}` } : undefined),
      Platform: 'console',
      'User-Language': navigator?.language,
      'Content-Type': 'application/json',
    }
  }

  private get = async <T>(
    path: string,
    params: { [key: string]: string | string[] },
    useToken: boolean = true
  ) => this.request<T>('GET', path, undefined, params, useToken)

  private delete = async <T>(
    path: string,
    params: { [key: string]: string },
    useToken: boolean = true
  ) => this.request<T>('DELETE', path, undefined, params, useToken)

  private post = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('POST', path, body, undefined, useToken)

  private put = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('PUT', path, body, undefined, useToken)

  private patch = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('PATCH', path, body, undefined, useToken)

  private cleanParams = (params: {
    [key: string]: string | number | null | undefined | string[]
  }) =>
    _(params)
      .pickBy((value) => value !== null && value !== undefined && value !== '')
      .mapValues((param) => {
        if (Array.isArray(param)) {
          return param.map((p) => String(p))
        }
        return String(param)
      })
      .value()

  private persistSession = (res: LoginResponse) => {
    storage.saveToken(res.token)
    storage.saveUser(res.user)
    return res
  }

  public clearSession = () => {
    storage.deleteToken()
    storage.deleteUser()
  }

  public login = ({ email, password }: { email: string; password: string }) =>
    this.post<LoginResponse>('/auth/login', { email, password }, false)
      .then((data) => {
        if (['admin', 'super_admin', 'operator', 'user'].includes(data.user.role)) {
          return data
        }
        throw new Error('insufficient_role')
      })
      .then(this.persistSession)

  public recoverPassword = ({ email }: { email: string }) =>
    this.post<{}>('/auth/otp/request', { email }, false)

  public loginOTP = (body: { email: string; otp: string }) =>
    this.post<LoginResponse>('/auth/otp/login', body, false).then(this.persistSession)

  public updatePassword = ({
    password,
    session,
  }: {
    password: string
    session?: LoginResponse
  }) => {
    if (session) {
      // save this one in localStorage before requesting
      this.persistSession(session)
    }
    return this.put<User>('/me/password', { password }).catch((err) => {
      if (session) {
        this.clearSession()
      }
      throw err
    })
  }

  public getMe = () => this.get<User>('/me', {})
  public getBillinginfo = () => this.get<Billing>('/me/billing_info', {})
  public updateBillinginfo = (body: Partial<Billing>) => this.put<Billing>('/me/billing_info', body)

  public updateMe = (body: UpdateUser) => this.patch<User>('/me', body)

  public getUsers = (params: ListParams) =>
    this.get<PaginatedResponse<User>>('/users', this.cleanParams(params))

  public getUser = (params: { id: string }) => this.get<User>(`/users/${params.id}`, {})

  public getCall = (params: { id: string }) => this.get<CallDetail>(`/calls/${params.id}`, {})

  public endCall = (params: { id: string; body: CallEnd }) =>
    this.patch(`/messages/${params.id}`, params.body)

  public getAppointment = (params: { body: { id: string; from: Date; to: Date } }) =>
    this.get<Period[]>(
      `/appointments/?id=${params.body.id}&from=${params.body.from}&to=${params.body.to}`,
      {}
    )

  public getUserService = (params: { id: string }) =>
    this.get<UserServices>(`/users/${params.id}/service`, {})

  public getUsersMessages = (params: ListParams & { id: string }) =>
    this.get<PaginatedResponse<UserMessage>>(
      `/users/${params.id}/messages`,
      this.cleanParams(_.omit(params, 'id'))
    )

  public getEvents = (
    params: ListParams & { date?: string; from?: string; to?: string; statuses?: EventStatus[] }
  ) => this.get<PaginatedResponse<Event>>('/events', this.cleanParams(params))

  public getUsersContacts = (params: ListParams & { id: string }) =>
    this.get<PaginatedResponse<UserContact>>(
      `/users/${params.id}/contacts`,
      this.cleanParams(_.omit(params, 'id'))
    )

  public createUser = (body: CreateUser) => this.post<User>('/users', body)

  public updateUser = ({ id, body }: { id: string; body: UpdateUser }) =>
    this.patch<User>(`/users/${id}`, body)

  public setAppointment = (params: { appointment: { from: string; to: string }; id: string }) =>
    this.patch(`/messages/${params.id}`, { appointment: params.appointment })

  public getAvailability = (params: { id: string }) =>
    this.get<AvailabilitySlot[]>(`/appointments/availability/user/${params.id}`, {})

  public deleteUser = (params: { id: string }) => this.delete<User>(`/users/${params.id}`, {})

  public redeemWelfareCode = (params: { code: string }) =>
    this.post<{ voucher: { plan: string } }>('/me/redeem-welfare-code', params)

  public getDashboard = (params: {}) => this.get<Dashboard>(`/dashboard`, {})

  public getProducts = (params: {}) => this.get<Products>(`/products`, {})

  public getProduct = (params: { id: string }) => this.get<Product>(`/products/${params.id}`, {})

  public checkout = (body: {
    price_id: string
    voucher_code: string
    mode: string
    plan_name?: string
  }) => this.post<CheckoutResponse>('/payments/create-checkout-session', body)
  public stripePortal = (body: { customer_id: string }) =>
    this.post<{ url: string }>('/payments/create-portal-session', body)
}

export type LoginResponse = { token: string; user: User }

type PaginatedResponse<T> = {
  items: T[]
  pagination: { current_page: number; total_pages: number; page_size: number }
}

type ListParams = {
  search_text?: string
  order_by?: string
  order_direction?: 'asc' | 'desc'
  page?: number
  page_size?: number
  [key: string]: string | number | undefined | string[]
}

export type CreateUser = Pick<User, 'first_name' | 'last_name' | 'email' | 'role'> & {
  password: string
}

export type UpdateUser = Pick<User, 'first_name' | 'last_name'>

export type CreateEvent = Partial<
  Pick<Event, 'title' | 'period' | 'permissions'> & { type: string }
>
export type UpdateEvent = CreateEvent

export const api = new API()
