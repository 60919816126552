import React, { useContext, useState, useCallback } from 'react'
import styled from 'styled-components'
import { ThemeContext, Button } from '@duckma/react-ds'

import Logo from '../images/logo.png'
import { storage } from '../data/storage'
import { useNavigate } from 'react-router-dom'
import { Header } from './Header'
import { User } from '../data/models'

export const SessionContext = React.createContext<{
  user?: User
  mustReload: () => void
  flagDirty: () => void
  resetDirty: () => void
}>({ mustReload: () => {}, flagDirty: () => {}, resetDirty: () => {} })

export const MainLayoutV2: React.FC<{
  title: string
  backLink?: { to: string; text: string }
  profileBotton?: boolean
  showName?: boolean
  headerButton?: { text: string; to: string }
  headerLeft?: React.ReactNode,
  children?: JSX.Element | JSX.Element[]
}> = ({ children, title, backLink, profileBotton, showName, headerButton, headerLeft }) => {
  const theme = useContext(ThemeContext)
  const history = useNavigate()
  // If a form is dirty (with unsaved user work on it) the page sets dirty to true.
  // If the user then tries to go back, a popup is shown
  const [dirty, setDirty] = useState(false)

  const [user, setUser] = useState(storage.getUser())
  const mustReload = useCallback(() => setUser(storage.getUser()), [])
  const flagDirty = useCallback(() => {
    window.onbeforeunload = () => ''
    setDirty(true)
  }, [])
  const resetDirty = useCallback(() => {
    window.onbeforeunload = () => null
    setDirty(false)
  }, [])

  return (
    <Container>
      <MainHeader>
        <LogoContainer>
          <LogoImg src={Logo} onClick={() => history('/')} />
        </LogoContainer>
        {profileBotton && (
          <ProfileButtonContainer>
            <Button
              style={{
                marginLeft: 'auto',
                width: window.screen.availWidth > 860 ? '' : '40px',
                height: '100%',
                backgroundColor: theme['primary20'],
              }}
              iconLeft={'user-alt'}
              text={window.screen.availWidth > 860 ? 'Profilo' : ''}
              radius={4}
              size={'small'}
              onClick={() => (window.location.href = '/me')}
            />
          </ProfileButtonContainer>
        )}
      </MainHeader>
      <div style={{ width: 'calc(100%-40px)', height: '40px', padding: '0 40px 0 0' }}>
        <LogoutText
          onClick={() => {
            storage.deleteUser()
            storage.deleteToken()
            history('/login')
          }}
        >
          Logout
        </LogoutText>
      </div>
      <HeaderContainer color={theme['white']}>
        <Header
          userName={showName ? user.last_name : undefined}
          title={title}
          userTitle={showName ? user.title : undefined}
          backLink={backLink}
          dirty={dirty}
          leftSide={
            headerButton ? (
              <>
                <Button
                  text={headerButton.text}
                  onClick={() => {
                    if (dirty) {
                      return (window.location.href = headerButton.to)
                    }
                    history(headerButton.to)
                  }}
                  size="large"
                  radius={4}
                />
              </>
            ) : (
              headerLeft
            )
          }
        ></Header>
      </HeaderContainer>
      <SessionContext.Provider value={{ mustReload, flagDirty, resetDirty, user }}>
        <ContentContainer color={theme['white']}>{children}</ContentContainer>
      </SessionContext.Provider>
    </Container>
  )
}

//const Grid = styled.div`
//  width: 100%;
//  height: 100%;
//  min-height: 100vh;
//  margin: 0;
//  padding: 0;
//  border: none;
//  display: grid;
//  grid-template-columns: 260px 1fr;
//  grid-template-rows: 160px 1fr;
//`

const Container = styled.div`
  width: 100%;
`
const MainHeader = styled.div`
  width: calc(100% - 40px);
  padding: 10px 40px 10px 0;
  display: flex;
`
const LogoImg = styled.img`
  width: 100%;
  cursor: pointer;
`

const HeaderContainer = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 100%;
`

const LogoContainer = styled.div`
  width: 140px;
`
const ProfileButtonContainer = styled.div`
  width: 100px;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 40px;
`

const ContentContainer = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 100%;
  grid-area: 2 / 2 / 3 / 3;
`

const LogoutText = styled.p`
  font-family: 'Open Sans', sans-serif;
  color: #3b7b95;
  width: 100px;
  height: 30px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  cursor: pointer;
  margin-left: auto;
  border-radius: 4px;
  :hover {
    background-color: #73a4ba55;
  }
  @media screen and (max-width: 860px) {
    margin-left: 0;
  }
`