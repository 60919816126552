import React from 'react'
import Modal from 'react-modal'
import { Button } from '@duckma/react-ds'
import styled from 'styled-components'
import _ from 'lodash'

Modal.setAppElement('#root')
const customStyles: Record<string, React.CSSProperties> = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '10vh 10vw',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,.5)',
  },
}

export const PlanModal: React.FC<{ activatedPlan?: string; onClose: () => unknown }> = ({
  activatedPlan,
  onClose,
}) => {
  return (
    <Modal isOpen={activatedPlan !== undefined} onRequestClose={onClose} style={customStyles}>
      <Subtitle>{`Hai attivato un abbonamento ${_.capitalize(activatedPlan)}.`}</Subtitle>
      <Description>Accedi all'app MySecretary per utilizzare il piano attivato</Description>
      <Button radius={4} text="Ok" onClick={onClose} style={{ width: '40%', margin: '0 30%' }} />
    </Modal>
  )
}

const Subtitle = styled.div`
  font-family: 'Open sans', sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: #222;
  font-weight: 600;
  margin-bottom: 14px;
`

const Description = styled.div`
  font-family: 'Open sans', sans-serif;
  font-size: 13px;
  line-height: 18px;
  color: #222;
  font-weight: 400;
  margin-bottom: 50px;
`
