import { Button, Input } from '@duckma/react-ds'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { api } from '../data/api'
import { useRequest } from '../hooks/useRequest'

export const WelfarCodeBox = () => {
  const history = useNavigate()
  const [companyVoucher, setCompanyVoucher] = useState('')
  const [validCode, setValidCode] = useState(true)
  const [errorCode, setErrorCode] = useState('prova errore')
  const [redeemWelfareCode, { data: redeemData, status: redeemStatus }] = useRequest(
    api.redeemWelfareCode
  )

  function redeemCode() {
    redeemWelfareCode({ code: companyVoucher })
  }

  useEffect(() => {
    if (redeemStatus === 'error') {
      setValidCode(false)
      setErrorCode('Codice non valido')
    } else if (redeemStatus === 'success') {
      history(`/dashboard?activatedPlan=${redeemData?.voucher.plan ?? 'Custom'}`)
    }
  }, [history, redeemData, redeemStatus])

  return (
    <CodeContainer>
      <CodeTitle>Hai un codice azienda o welfare? </CodeTitle>
      <Input
        style={{ marginBottom: '20px' }}
        type="text"
        name=""
        valid={validCode}
        value={companyVoucher}
        onChange={setCompanyVoucher}
        errorText={errorCode}
      />
      <Button
        style={{ fontWeight: '400' }}
        color="primary"
        text="Riscatta"
        onClick={redeemCode}
        size="large"
        radius={5}
      />
    </CodeContainer>
  )
}

const CodeContainer = styled.div`
  width: calc(100% - 40px);
  padding: 20px;
  margin-top: 50px;
  background-color: #f7f7f7;
  border-radius: 20px;
`
const CodeTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  color: #3b7b95;
`
