import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { loadStripe } from '@stripe/stripe-js'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { BillingForm } from '../../components/billingform'
import { SaleCodeBox } from '../../components/SaleCodeBox'
import { Billing, BillingType, Product } from '../../data/models'
import { Button } from '@duckma/react-ds'
import { useLocation, useParams } from 'react-router-dom'
import { SkeletonBillingForm } from '../../components/SkeletonBillingForm'

const initialBillingInfo: Billing = {
  first_name: '',
  last_name: '',
  tax_code: '',
  country: '',
  zip_code: '',
  state: '',
  city: '',
  birthday: null,
  type: BillingType.PERSONAL,
  address: '',
  business_name: '',
  vat_number: '',
  sdi: '',
  pec: '',
}

export const CheckoutPage = () => {
  const stripePromise = loadStripe(
    'pk_live_51JZXbhB8FXhf940s0P9dj7Wmw2TUwMQlp08NCpmDeWoF5WOgsR2Xa8pTxaYEtmj4g3PcbEtxdQk34bevLf6GPwYO00f8oQxWpB'
  )

  const { id } = useParams<{ id: string }>()
  const { state } = useLocation()
  const [billingInfo, setBillingInfo] = useState(initialBillingInfo)
  const [voucherCode, setVoucherCode] = useState('')
  const [product, setProduct] = useState<Product>()
  const [validBillingInfo, setValidBillingInfo] = useState(true)
  const [getProduct, { data: productData, status: productStatus }] = useRequest(api.getProduct)
  const [updateBillinginfo, { status: updateStatus }] = useRequest(api.updateBillinginfo)
  const [goToCheckout, { status: checkoutStatus, data: checkoutData }] = useRequest(api.checkout)
  const [getBillinginfo, { status: billingStatus, data: billingData }] = useRequest(
    api.getBillinginfo
  )
  useEffect(() => {
    if (billingStatus === 'idle') {
      getBillinginfo({})
    }
  }, [getBillinginfo, billingStatus])

  useEffect(() => {
    if (productStatus === 'idle' && id) {
      getProduct({ id })
    }
    if (productStatus === 'success' && productData) {
      setProduct(productData)
    }
  }, [getProduct, productStatus, productData, id])

  async function continuePayments() {
    updateBillinginfo(billingInfo)
  }

  const redirect = useCallback(
    async (sessionId: string) => {
      const stripe = await stripePromise
      await stripe?.redirectToCheckout({
        sessionId: sessionId,
      })
    },
    [stripePromise]
  )

  useEffect(() => {
    if (updateStatus === 'success' && product) {
      goToCheckout({
        price_id: product.price_id,
        voucher_code: voucherCode,
        mode: product.talk_time_seconds ? 'payment' : 'subscription',
        plan_name: state?.avoidPlanModal !== true ? product.plan! : undefined,
      })
    }
  }, [updateStatus, product, goToCheckout, voucherCode, state])

  useEffect(() => {
    if (checkoutStatus === 'success' && checkoutData) {
      redirect(checkoutData.id)
    }
  }, [checkoutStatus, checkoutData, redirect])

  return (
    <Container>
      <SubContainer>
        <BillingInfoContainer style={{ marginTop: billingStatus === 'success' ? '' : '30px' }}>
          {billingStatus !== 'success' && <SkeletonBillingForm />}
          {billingStatus === 'success' && (
            <BillingForm
              billingInfo={billingData}
              onChange={(data: { billing: Billing; valid: boolean }) => {
                setValidBillingInfo(data.valid)
                setBillingInfo(data.billing)
              }}
            />
          )}
        </BillingInfoContainer>
        <CodeContainer>
          <SaleCodeBox code={voucherCode} onChange={(data) => setVoucherCode(data)} />
        </CodeContainer>
      </SubContainer>
      <ContinueContainer>
        <ContinueButton
          size="medium"
          text="Procedi con il pagamento"
          iconRight="arrow-right"
          radius={4}
          disabled={!validBillingInfo}
          onClick={continuePayments}
          color={validBillingInfo ? 'primary' : 'gray'}
        />
      </ContinueContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 50px;
  @media screen and (max-width: 860px) {
    width: calc(100% - 32px);
    padding: 0px 16px;
    padding-bottom: 50px;
  }
`

const SubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  padding-bottom: 20px;
`

const BillingInfoContainer = styled.div`
  width: calc(60% - 40px);
  padding: 0 20px;
  @media screen and (max-width: 860px) {
    width: calc(100%);
    padding: 0;
  }
`

const CodeContainer = styled.div`
  width: calc(40% - 40px);
  padding: 0 20px;
  @media screen and (max-width: 860px) {
    width: calc(100%);
    padding: 0;
  }
`

const ContinueContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`

const ContinueButton = styled(Button)`
  margin-left: auto;
  width: 260px;
  @media screen and (max-width: 860px) {
    margin-right: auto;
  }
`
