import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Input } from '@duckma/react-ds'

import LogoImg from '../../images/logo.png'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'

import AppStore from '../../images/app-store.svg'
import PlayStore from '../../images/play-store.svg'
import { Navigate } from 'react-router-dom'

export const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [request, { status, data }] = useRequest(api.login)

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault()
        request({ email, password })
      }}
    >
      {data && <Navigate to="/" />}
      <Logo src={LogoImg} alt="Logo" />
      <Input
        name="email"
        type="email"
        label=""
        placeholder="Inserisci indirizzo email"
        size="large"
        value={email}
        onChange={setEmail}
        style={{ marginBottom: '30px' }}
      />
      <Input
        name="password"
        type="password"
        label=""
        placeholder="Inserisci password"
        size="large"
        value={password}
        onChange={setPassword}
        style={{ marginBottom: '30px' }}
      />
      <Button
        text="Login"
        radius={4}
        loading={status === 'loading'}
        style={{ width: '50%', marginTop: '50px' }}
      />
      <InfoContainer>
        <div>La tua Segretaria in un'App.</div>
        <div>Scarica l'app e registrati!</div>
        <ButtonsContainer>
          <a href="https://duckma.com">
            <img src={AppStore} alt="App Store" />
          </a>
          <a href="https://duckma.com">
            <img src={PlayStore} alt="Play Store" />
          </a>
        </ButtonsContainer>
      </InfoContainer>
    </Container>
  )
}

const Container = styled.form`
  width: 40%;
  padding-left: 30%;
  padding-right: 30%;
  padding-top: 10vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 860px) {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
  }
`

const Logo = styled.img`
  width: 30vw;
  object-fit: contain;
  @media screen and (max-width: 860px) {
    width: 50vw;
    margin-bottom: 5vh;
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    color: #55b3d3;
    line-height: 24px;
    font-weight: 400;
  }
  > :first-child {
    margin-top: 10vh;
    font-weight: 700;
  }
`

const ButtonsContainer = styled.div`
  margin-top: 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  > :first-child {
    margin-right: 20px;
  }
`
