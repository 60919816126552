import React from 'react'
import { ThemeContext } from '@duckma/react-ds'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import { theme } from './styles/theme'
import { LoginPage } from './modules/auth/Login'
import './styles/global.css'
import { storage } from './data/storage'
import { UsersPage } from './modules/users/Users'
import { PasswordReset } from './modules/auth/PasswordReset'
import { PasswordOTP } from './modules/auth/PasswordOTP'
import { UpdatePassword } from './modules/auth/UpdatePassword'
import { MainLayoutV2 as MainLayout } from './components/MainLayoutV2'
import { ProfilePage } from './modules/profile/Profile'
import { DashboardPage } from './modules/dashboard/Dashboard'
import { CreateUser } from './modules/users/CreateUser'
import { UserDetail } from './modules/users/UserDetail'
import { AppContextProvider } from './components/AppContext'
import { CheckoutPage } from './modules/checkout/CheckoutPage'

const PrivateRoute = ({
	component: Component,
	header
}: {
	component: React.ComponentType
	header: React.ComponentProps<typeof MainLayout>
	[key: string]: any
}) => {
	return (
		storage.getToken() !== null ? (
			<MainLayout {...header}>
				<Component/>
			</MainLayout>
		) : (
			<Navigate to="/login" />
		)
	)
}

function App() {
return (
    <ThemeContext.Provider value={theme}>
	<AppContextProvider>
        <Router>
			<Routes>
				<Route path="/login" element={<LoginPage />} />
				<Route path="/password-reset" element={<PasswordReset />} />
				<Route path="/password-otp" element={<PasswordOTP />} />
				<Route path="/update-password" element={<UpdatePassword />} />
				<Route path="/" element={<Navigate to="/dashboard" />} />
				<Route path="/users/create" element={<PrivateRoute
						component={CreateUser}
						header={{
							title: 'Aggiungi nuovo utente',
							backLink: {
								text: 'Torna alla lista utenti',
								to: '/users',
							},
						}}
					/>} />
							
				<Route path="/dashboard" element={<PrivateRoute
						component={DashboardPage}
						header={{
							title: 'Benvenuto/a',
							showName: true,
							profileBotton: true,
						}}
					/>} />
				
				<Route path="/users/:id" element={<PrivateRoute
						component={UserDetail}
						header={{
							title: 'Dettaglio Utente',
							backLink: {
								text: 'Torna alla lista utenti',
								to: '/users',
							},
						}}
					/>} />
				
				<Route path="/checkout/:id" element={<PrivateRoute
						component={CheckoutPage}
						header={{
							title: 'Inserisci i tuoi dati',
							backLink: {
								text: 'Torna alla home',
								to: '/',
							},
						}}
					/>} />
				
				<Route path="/users" element={<PrivateRoute
						component={UsersPage}
						header={{
							title: 'Utenti',
							headerButton: {
								text: 'Aggiungi nuovo utente',
								to: '/users/create',
							},
						}}
					/>} />
				
				<Route path="/me" element={<PrivateRoute
						component={ProfilePage}
						header={{
							title: 'Profilo',
							backLink: {
								text: 'Torna alla home',
								to: '/',
							},
						}}
					/>} />
			</Routes>
        </Router>
	</AppContextProvider>
    </ThemeContext.Provider>
)
}

export default App