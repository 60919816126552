import { Button } from '@duckma/react-ds'
import React from 'react'
import styled from 'styled-components'

type Props = {
  description?: string
  title: string
  noBorderBottom?: boolean
  price: string
  disableButton?: boolean
  onClick: () => void
}

export const ProductCard = ({
  description,
  title,
  noBorderBottom,
  disableButton = false,
  price,
  onClick,
}: Props) => {
  return (
    <ProductCardContainer style={{ borderBottom: noBorderBottom ? 'none' : '' }}>
      <Card>
        <ProductTitle>{title}</ProductTitle>
        <ProductPrice>{price}</ProductPrice>
        <ProductDescription>{description}</ProductDescription>
      </Card>
      <ProductButton
        disabled={disableButton}
        color={disableButton ? 'gray' : 'primary'}
        radius={4}
        text="Acquista"
        onClick={onClick}
      />
    </ProductCardContainer>
  )
}

const ProductCardContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  display: flex;
`

const Card = styled.div`
  width: calc(100% - 180px);
  @media screen and (max-width: 860px) {
    width: 70%;
  }
`

const ProductButton = styled(Button)`
  margin-top: 12px;
  margin-left: auto;
  height: 40px;
  margin-right: auto;
  font-weight: 400;
  width: 120px;
  @media screen and (max-width: 860px) {
    width: 104;
  }
`

const ProductTitle = styled.p`
  font-size: 15px;
  font-weight: 400;
  margin: 8px 0;
  font-family: 'Open sans', sans-serif;
  color: #222;
`
const ProductDescription = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin: 8px 0;
  font-family: 'Open sans', sans-serif;
  color: #828894;
`
const ProductPrice = styled.p`
  font-size: 13px;
  margin: 8px 0;
  font-weight: 400;
  font-family: 'Open sans', sans-serif;
  color: #222;
`
