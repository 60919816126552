import React, { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext, Icon, HeaderTitle, Theme  } from '@duckma/react-ds'

import { useNavigate } from 'react-router-dom'
import { UserTitle } from '../data/models'

type Props = {
  title: string
  backLink?: { to: string; text: string }
  leftSide?: React.ReactNode
  dirty?: boolean
  userName?: string
  userTitle?: UserTitle
}

const userTitleTranslate: { [key in UserTitle]: string } = {
  [UserTitle.NONE]: 'Nessun titotolo selezionato',
  [UserTitle.MISTER]: 'Sig.',
  [UserTitle.MISSUS]: 'Sig.ra',
  [UserTitle.DOCTOR_M]: 'Dottor',
  [UserTitle.ENGINEER]: 'Ingegnere',
  [UserTitle.DOCTOR_F]: 'Dottoressa',
  [UserTitle.MISS]: 'Sig.na',
  [UserTitle.ACCOUNTANT]: 'Ragioniere',
  [UserTitle.ADMINISTRATOR]: 'Amministratore',
  [UserTitle.ATTORNEY]: 'Avvocato',
  [UserTitle.SURVEYOR]: 'Geometra',
  [UserTitle.DIRECTOR]: 'Direttore',
  [UserTitle.ARCHITECT]: 'Architetto',
  [UserTitle.NOTARY]: 'Notaio',
}

export const Header: React.FC<Props> = ({
  title,
  backLink,
  userName,
  leftSide,
  dirty,
  userTitle,
}) => {
  const theme: Theme = useContext(ThemeContext) as Theme;
  const history = useNavigate()

  return (
    <Container>
      {backLink && (
        <BackContainer
          onClick={() => {
            if (dirty) {
              return (window.location.href = backLink.to)
            }
            history(backLink.to)
          }}
        >
          <Icon name="arrow-left" color="gray50" size="18px" />
          <BackText color={theme['gray100']}>{backLink.text}</BackText>
        </BackContainer>
      )}
      <TitleContainer>
        <StyledTitle color={'primary100'}>
          {title} {userTitle ? userTitleTranslate[userTitle] : null} {userName}
        </StyledTitle>
        <div style={{ flexGrow: 1 }} />
        <div>{leftSide} </div>
      </TitleContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 100px);
  padding: 0;
  margin: 0;
  border: none;
  padding-left: 50px;
  padding-right: 50px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media screen and (max-width: 860px) {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
  }
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-between;
  align-items: center;
`

const StyledTitle = styled(HeaderTitle)`
  display: inline-block;
  margin: 0;
  font-weight: 400;
  @media screen and (max-width: 860px) {
    font-size: 26px;
  }
`

const BackContainer = styled.button`
  background-color: transparent;
  border: none;
  outline: false;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const BackText = styled.p<{ color: string }>`
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  margin-left: 12px;
  color: ${(props) => props.color};
`
