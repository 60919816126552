import React, { useState, useMemo, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Column, Ordering, Paginator, Subtitle, Table } from '@duckma/react-ds'

import { useRequest } from '../../../hooks/useRequest'
import { api } from '../../../data/api'
import { User, UserContact } from '../../../data/models'
import { SkeletonTable } from '../../../components/SkeletonTable'

export const ContactsTab: React.FC<{ user: User }> = ({ user }) => {
  const [getContacts, { status, data }] = useRequest(api.getUsersContacts)

  const [ordering, setOrdering] = useState<Ordering<UserContact>>({
    key: 'name',
    direction: 'asc',
  })
  const [page, setPage] = useState<number>(0)

  const getMessagesWithParams = useCallback(
    () =>
      getContacts({
        order_by: ordering.key,
        order_direction: ordering.direction,
        page,
        page_size: 10,
        id: user.id,
      }),
    [getContacts, ordering.direction, ordering.key, page, user.id]
  )

  useEffect(() => {
    getMessagesWithParams()
  }, [getMessagesWithParams])

  const columns = useMemo(
    () =>
      [
        { key: 'name', name: 'Nominativo', orderable: true },
        { key: 'phone_number', name: 'Numero di telefono', orderable: true },
      ] as Column<UserContact>[],
    []
  )

  return (
    <Container>
      {status !== 'loading' ? (
        <Table
          columns={columns}
          records={data ? data.items : []}
          order={ordering}
          onOrder={setOrdering}
        />
      ) : (
        <SkeletonTable />
      )}
      {status === 'success' && data && data.items.length > 0 && (
        <Paginator
          style={{ marginTop: '75px', marginBottom: '25px' }}
          currentPage={page}
          totalPages={data.pagination.total_pages}
          onPageSelect={setPage}
        />
      )}
      {status === 'success' && data && data.items.length === 0 && (
        <Subtitle text="Nessun utente trovato" color="gray100" />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
`
