import React from 'react'
import Skeleton from 'react-loading-skeleton'
import _ from 'lodash'

export const SkeletonBillingForm = () => {
  return (
    <div>
      <Skeleton width="30%" height="30px" style={{ marginRight: '20px' }} />
      <Skeleton width="30%" height="30px" style={{ marginBottom: '20px' }} />
      {_.times(8, (i) => (
        <Skeleton key={i} width="100%" height="30px" style={{ marginBottom: '20px' }} />
      ))}
    </div>
  )
}
