import React from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';

const container = document.getElementById('root');
const rootElement = container as Element | DocumentFragment;

if (container) {
    createRoot(rootElement).render(
        <React.StrictMode>
        <div>
            <App />
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} pauseOnFocusLoss={false} />
        </div>
        </React.StrictMode>
    );
} else {
    console.error('Element with id "root" not found.');
}