import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Input } from '@duckma/react-ds'

import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { useDirty } from '../../hooks/useDirty'
import { Billing, BillingType, UserTitle } from '../../data/models'
import moment from 'moment'
import 'moment/locale/it'
import { SubscriptionBox } from '../../components/SubscriptionBox'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { BillingForm } from '../../components/billingform'
import { SkeletonBillingForm } from '../../components/SkeletonBillingForm'
import { SkeletonMe } from '../../components/SkeletonMe'

moment().locale('it')
const userTitle: { [key in UserTitle]: string } = {
  [UserTitle.NONE]: 'Nessun titotolo selezionato',
  [UserTitle.MISTER]: 'Signore',
  [UserTitle.MISSUS]: 'Signorina',
  [UserTitle.DOCTOR_M]: 'Dottor',
  [UserTitle.ENGINEER]: 'Ingegnere',
  [UserTitle.DOCTOR_F]: 'Dottoressa',
  [UserTitle.MISS]: 'Signora',
  [UserTitle.ACCOUNTANT]: 'Ragioniere',
  [UserTitle.ADMINISTRATOR]: 'Amministratore',
  [UserTitle.ATTORNEY]: 'Avvocato',
  [UserTitle.SURVEYOR]: 'Geometra',
  [UserTitle.DIRECTOR]: 'Direttore',
  [UserTitle.ARCHITECT]: 'Architetto',
  [UserTitle.NOTARY]: 'Notaio',
}

const initialBillingInfo: Billing = {
  first_name: '',
  last_name: '',
  tax_code: '',
  country: '',
  zip_code: '',
  state: '',
  city: '',
  birthday: null,
  type: BillingType.PERSONAL,
  address: '',
  business_name: '',
  vat_number: '',
  sdi: '',
  pec: '',
}

export const ProfilePage = () => {
  const [request, { status, data }] = useRequest(api.getMe)
  const [billingInfo, setBillingInfo] = useState(initialBillingInfo)
  const [validBillingInfo, setValidBillingInfo] = useState(true)
  const [updateBillinginfo, { status: updateStatus }] = useRequest(api.updateBillinginfo)
  const [getBillinginfo, { status: billingStatus, data: billingData }] = useRequest(
    api.getBillinginfo
  )

  useSuccessToast(updateStatus, 'Dati aggiornati correttamente', '/me')

  useEffect(() => {
    if (status === 'idle') {
      request({})
    }
  }, [request, status])

  useEffect(() => {
    if (billingStatus === 'idle') {
      getBillinginfo({})
    }
  }, [getBillinginfo, billingStatus])

  useDirty(data, ['first_name', 'last_name'])

  function updateUserBillingInfo() {
    updateBillinginfo(billingInfo)
  }

  return (
    <Container>
      <BillingInfoContainer>
        {billingStatus !== 'success' && <SkeletonBillingForm />}
        {billingStatus === 'success' && (
          <BillingForm
            billingInfo={billingData}
            onChange={(data: { billing: Billing; valid: boolean }) => {
              setValidBillingInfo(data.valid)
              setBillingInfo(data.billing)
            }}
          />
        )}
        <SaveButton
          disabled={!validBillingInfo}
          onClick={updateUserBillingInfo}
          text="Salva modifiche"
          radius={4}
          color={validBillingInfo ? 'primary' : 'gray'}
        />
      </BillingInfoContainer>
      <UserInfoContainer>
        <UserBoxTitle>Dati personali</UserBoxTitle>
        {status !== 'success' && <SkeletonMe />}
        {status === 'success' && (
          <>
            <UserInput
              disabled
              type="text"
              name="title"
              value={userTitle[data?.title ?? UserTitle.NONE]}
              label=""
            />
            <UserInput disabled type="text" name="name" value={data?.first_name} label="" />
            <UserInput disabled type="text" name="last_nme" value={data?.last_name} label="" />
            <UserInput disabled type="text" name="email" value={data?.email} label="" />
          </>
        )}
        {data?.subscription.expires_at && (
          <SubscriptionBox title="informazioni Piano" subscription={data.subscription} />
        )}
      </UserInfoContainer>

      <div style={{ width: '100%', height: '150px' }}></div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 50px;
  @media screen and (max-width: 860px) {
    flex-flow: column;
    width: calc(100% - 32px);
    padding: 0px 16px;
    padding-bottom: 50px;
  }
`

const BillingInfoContainer = styled.div`
  width: calc(60% - 40px);
  margin-bottom: 40px;
  padding: 0 20px;
  @media screen and (max-width: 860px) {
    width: calc(100%);
    padding: 0;
  }
`
const UserInfoContainer = styled.div`
  width: calc(40% - 40px);
  padding: 0 20px;
  @media screen and (max-width: 860px) {
    width: calc(100%);
    padding: 0;
  }
`

const UserInput = styled(Input)`
  margin-bottom: 20px;
  Input {
    background-color: #fff !important;
    color: #b9bdc6;
    padding: 14px 16px;
  }
`

const UserBoxTitle = styled.p`
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  color: #55b3d3;
  margin-bottom: 40px;
`

const SaveButton = styled(Button)`
  width: 180px;
  margin-left: auto;
  margin-top: 30px;
  @media screen and (max-width: 860px) {
    margin-right: auto;
  }
`
