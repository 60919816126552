import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

export const SkeletonTable = ({ count = 5 }: { count?: number }) => (
  <Skeletons>
    <SkeletonHeader>
      <Skeleton count={1} />
    </SkeletonHeader>
    <SkeletonBody>
      <Skeleton count={count} />
    </SkeletonBody>
  </Skeletons>
)

const Skeletons = styled.div`
  width: 100%;
  &:nth-child(1) {
    margin-bottom: 20px;
    height: 30px;
  }
  &:first-child {
    height: 50px;
    margin-bottom: 50px;
  }
`

const SkeletonHeader = styled.div`
  width: 100%;
  * {
    height: 50px;
    margin-bottom: 50px;
  }
`

const SkeletonBody = styled.div`
  width: 100%;
  * {
    margin-bottom: 20px;
    height: 30px;
  }
`
