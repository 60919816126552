import { DateTimePicker, Radios } from '@duckma/react-ds'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Billing, BillingType } from '../data/models'
import { requiredValidator } from '../utils/validators'
import { ControlledField, ControlledForm } from './ControlledForm'

type Props = {
  billingInfo: Billing | null
  redirect?: string
  onChange: (data: { billing: Billing; valid: boolean }) => void
}

const profileChoices = [
  { key: BillingType.PERSONAL, label: 'Privato' },
  { key: BillingType.COMPANY, label: 'Azienda' },
]

const initialBillingInfo: Billing = {
  first_name: '',
  last_name: '',
  tax_code: '',
  country: '',
  zip_code: '',
  state: '',
  city: '',
  birthday: null,
  type: BillingType.PERSONAL,
  address: '',
  business_name: '',
  vat_number: '',
  sdi: '',
  pec: '',
}

export const BillingForm = ({ billingInfo, redirect, onChange }: Props) => {
  const [profile, setProfile] = useState('personal')
  const [values, setValues] = useState<Partial<Billing>>({})
  const [valid, setValid] = useState(true)
  const [date, setDate] = useState<Date | null>(null)
  const [validDate, setValidDate] = useState(true)
  const [tuched, setTuched] = useState(false)

  useEffect(() => {
    onChange({ billing: values as Billing, valid: valid })
  }, [values, onChange, valid])

  useEffect(() => {
    if (profile === BillingType.COMPANY) {
      setDate(null)
      setTuched(false)
    }
    if (date === null && tuched && profile === BillingType.PERSONAL) {
      setValidDate(false)
    } else {
      setValidDate(true)
    }
  }, [date, profile, tuched])

  useEffect(() => {
    setValues(initialBillingInfo)
  }, [profile])

  useEffect(() => {
    if (billingInfo) {
      setProfile(billingInfo.type ?? 'personal')
      if (billingInfo.birthday !== undefined && billingInfo.birthday !== null) {
        setDate(moment(billingInfo.birthday).toDate())
      }
    }
  }, [billingInfo])

  const initialValues = useMemo(() => {
    return billingInfo !== null
      ? ({
          ..._.omit(billingInfo, ['id', 'UserId']),
        } as { [key: string]: string | undefined })
      : ({
          ..._.omit(initialBillingInfo, ['id', 'UserId']),
        } as { [key: string]: string | undefined })
  }, [billingInfo])

  const onFormChange = useCallback(
    (v: any, valid: boolean) => {
      let previusValue = v
      previusValue.birthday = profile === BillingType.PERSONAL ? moment(date).format() : null
      previusValue.type = profile as BillingType
      setValues(v)
      setValid(valid && validDate)
    },
    [date, profile, validDate]
  )

  return (
    <BillingBox>
      <BillingTitle>Dati di Fatturazione </BillingTitle>
      <BillingRadios left choices={profileChoices} value={profile} onChange={setProfile} />

      {profile !== 'company' && (
        <ControlledForm initialValues={initialValues} onChange={onFormChange}>
          <BillingControlledField
            label=""
            style={{ marginBottom: '20px', marginTop: '45px' }}
            name="nome"
            placeholder="Inserire Nome"
            fieldName="first_name"
            validator={requiredValidator()}
          />
          <BillingControlledField
            label=""
            style={{ marginBottom: '20px' }}
            name="cognome"
            placeholder="Inserire Cognome"
            fieldName="last_name"
            validator={requiredValidator()}
          />
          <BillingDateTimePicker
            name="attivo da"
            selected={date}
            dateFormat={'dd/MM/yyyy'}
            label=""
            placeholderText="Inserire Data di Nascita"
            onChange={setDate}
            onInputClick={() => setTuched(true)}
            errorText="Campo obbligatorio"
            valid={validDate}
          />
          <BillingControlledField
            style={{ marginBottom: '20px' }}
            label=""
            name="Tax code"
            placeholder="Inserire Codice fiscale"
            fieldName="tax_code"
            validator={requiredValidator()}
          />
          <BillingControlledField
            style={{ marginBottom: '20px' }}
            label=""
            name="Tax code"
            placeholder="Inserire Nazione"
            fieldName="country"
            validator={requiredValidator()}
          />
          <div style={{ width: '100%', display: 'flex' }}>
            <BillingControlledField
              style={{ marginBottom: '20px', width: '45%' }}
              label=""
              name="Tax code"
              placeholder="Inserire Città"
              fieldName="city"
              validator={requiredValidator()}
            />
            <BillingControlledField
              style={{ marginBottom: '20px', marginLeft: '5%', width: '15%' }}
              label=""
              name="Tax code"
              placeholder="Prov."
              fieldName="state"
              validator={requiredValidator()}
            />
            <BillingControlledField
              label=""
              style={{ marginBottom: '20px', marginLeft: '5%', width: '30%' }}
              name="indirizzo"
              placeholder="Cod. postale"
              fieldName="zip_code"
              validator={requiredValidator()}
            />
          </div>
          <BillingControlledField
            label=""
            style={{ marginBottom: '20px' }}
            name="indirizzo"
            placeholder="Inserire Indirizzo"
            fieldName="address"
          />
        </ControlledForm>
      )}
      {profile === 'company' && (
        <ControlledForm initialValues={initialValues} onChange={onFormChange}>
          <BillingControlledField
            label=""
            style={{ marginBottom: '20px', marginTop: '45px' }}
            name="nome"
            placeholder="Inserire Nome"
            fieldName="first_name"
            validator={requiredValidator()}
          />
          <BillingControlledField
            label=""
            style={{ marginBottom: '20px' }}
            name="cognome"
            placeholder="Inserire Cognome"
            fieldName="last_name"
            validator={requiredValidator()}
          />
          <BillingControlledField
            style={{ marginBottom: '20px' }}
            label=""
            name="Nome"
            placeholder="Inserire Nome Azienda"
            fieldName="business_name"
            validator={requiredValidator()}
          />
          <BillingControlledField
            label=""
            style={{ marginBottom: '20px' }}
            name="cognome"
            placeholder="Inserire P.Iva"
            fieldName="vat_number"
            validator={requiredValidator()}
          />
          <BillingControlledField
            label=""
            style={{ marginBottom: '20px' }}
            name="tax_code"
            placeholder="Inserisci Codice Fiscale"
            fieldName="tax_code"
            validator={requiredValidator()}
          />
          <BillingControlledField
            style={{ marginBottom: '20px' }}
            label=""
            name="Tax code"
            placeholder="Inserire Nazione"
            fieldName="country"
            validator={requiredValidator()}
          />
          <div style={{ width: '100%', display: 'flex' }}>
            <BillingControlledField
              style={{ marginBottom: '20px', width: '45%' }}
              label=""
              name="Tax code"
              placeholder="Inserire Città"
              fieldName="city"
              validator={requiredValidator()}
            />
            <BillingControlledField
              style={{ marginBottom: '20px', marginLeft: '5%', width: '15%' }}
              label=""
              name="Tax code"
              placeholder="Prov."
              fieldName="state"
              validator={requiredValidator()}
            />
            <BillingControlledField
              label=""
              style={{ marginBottom: '20px', marginLeft: '5%', width: '30%' }}
              name="indirizzo"
              placeholder="Cod. postale"
              fieldName="zip_code"
              validator={requiredValidator()}
            />
          </div>
          <BillingControlledField
            label=""
            style={{ marginBottom: '20px' }}
            name="indirizzo"
            placeholder="Inserire Indirizzo"
            fieldName="address"
          />

          <BillingControlledField
            style={{ marginBottom: '20px' }}
            label=""
            name="Tax code"
            placeholder="Inserire PEC"
            fieldName="pec"
            validator={requiredValidator()}
          />
          <BillingControlledField
            style={{ marginBottom: '20px' }}
            label=""
            name="Tax code"
            placeholder="Inserire Codice SDI"
            fieldName="sdi"
            validator={requiredValidator()}
          />
        </ControlledForm>
      )}
    </BillingBox>
  )
}

const BillingControlledField = styled(ControlledField)`
  Input {
    padding: 14px 16px;
  }
`

const BillingDateTimePicker = styled(DateTimePicker)`
  margin-bottom: 20px;
  Input {
    padding: 14px 0px 14px 16px;
  }
`

const BillingBox = styled.div`
  display: flex;
  flex-flow: wrap;
`

const BillingRadios = styled(Radios)`
  @media screen and (max-width: 860px) {
    > div {
      display: flex;
      flex-flow: row-reverse;
    }
    button {
      margin-right: 10px;
    }
  }
`
const BillingTitle = styled.p`
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  color: #55b3d3;
  margin-bottom: 40px;
`
