import { Theme } from '@duckma/react-ds'

const realTheme: Theme = {
  black: '#222222',
  white: '#ffffff',
  primary100: '#3b7b95',
  primary50: '#55b3d3',
  primary20: '#73A4BA',
  secondary100: '#55B3D3',
  secondary50: '#59BEDC',
  secondary20: '#8ED9EF',
  tertiary100: '#77E7D2',
  tertiary50: '#b1f0e4',
  tertiary20: '#e0f9f5',
  gray100: '#828894',
  gray50: '#e5e7eb',
  gray20: '#f7f7f7',
  success100: '#a1dd70',
  success50: '#d0edb8',
  success20: '#e8f6dd',
  warning100: '#fdd043',
  warning50: '#fee69a',
  warning20: '#fff6da',
  danger100: '#ff5959',
  danger50: '#feaeae',
  danger20: '#fedcdc',
}

export const theme = realTheme
