import React from 'react'
import Skeleton from 'react-loading-skeleton'
import _ from 'lodash'

export const SkeletonMe = () => {
  return (
    <div>
      {_.times(4, (i) => (
        <Skeleton key={i} width="100%" height="30px" style={{ marginBottom: '20px' }} />
      ))}
    </div>
  )
}
