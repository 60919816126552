import { Input } from '@duckma/react-ds'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

type Props = {
  code: string
  onChange: (code: string) => void
}

export const SaleCodeBox = ({ code, onChange }: Props) => {
  const [companyVoucher, setCompanyVoucher] = useState(code)

  useEffect(() => {
    onChange(companyVoucher)
  }, [onChange, companyVoucher])

  return (
    <CodeContainer>
      <CodeTitle>Hai un codice sconto? </CodeTitle>
      <Input
        style={{ marginBottom: '20px' }}
        type="text"
        name=""
        value={companyVoucher}
        onChange={setCompanyVoucher}
      />
    </CodeContainer>
  )
}

const CodeContainer = styled.div`
  width: calc(100% - 40px);
  padding: 20px;
  margin-top: 20px;
  background-color: #f7f7f7;
  border-radius: 20px;
`
const CodeTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  font-family: 'Open sans', sans-serif;
  color: #3b7b95;
`
