import { useEffect, useContext } from 'react'
import { RequestStatus } from './useRequest'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SessionContext } from '../components/MainLayout'

export const useSuccessToast = (requestStatus: RequestStatus, msg: string, navigateTo?: string) => {
  const history = useNavigate()
  const { resetDirty } = useContext(SessionContext)

  useEffect(() => {
    if (requestStatus === 'success') {
      toast(msg, { type: 'success' })
      resetDirty()
      if (navigateTo) {
        history(navigateTo)
      }
    }
  }, [requestStatus, msg, navigateTo, resetDirty, history])
}
