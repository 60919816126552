/**
 * This file defines various input formatters of general use.
 * Single use functions shouldn't be put here.
 */
import moment from 'moment'

export type Formatter<T> = (value: T) => string

export const dateFormatter: Formatter<string> = (date: string) => moment(date).format('DD/MM/YYYY')
export const dateTimeFormatter: Formatter<string> = (date: string) =>
  moment(date).format('DD/MM/YYYY HH:mm')
