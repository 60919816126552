import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import moment from 'moment'
import { Button } from '@duckma/react-ds'
import qs from 'qs'

import { ProductCard } from '../../components/ProductCard'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { OneshotProduct, SubscriptionProduct, User } from '../../data/models'
import { SubscriptionBox } from '../../components/SubscriptionBox'
import { WelfarCodeBox } from '../../components/WelfarCodeBox'
import { useNavigate, useLocation } from 'react-router'
import { SkeletonProductCard } from '../../components/SkeletonProductCard'
import { SkeletonBox } from '../../components/SkeletonBox'
import { PlanModal } from './PlanModal'

export const DashboardPage = () => {
  const { search = '' } = useLocation()
  const { activatedPlan } = qs.parse(search, { ignoreQueryPrefix: true })
  const [getProducts, { status, data }] = useRequest(api.getProducts)
  const [request, { status: meStatus, data: meData }] = useRequest(api.getMe)
  const [stripePortal, { status: portalStatus, data: portalData }] = useRequest(api.stripePortal)
  const history = useNavigate()
  const [validSub, setValidSub] = useState(false)
  const [user, setUser] = useState<User>()
  useEffect(() => {
    getProducts({})
  }, [getProducts])

  useEffect(() => {
    if (meStatus === 'idle') {
      request({})
    }
    if (meStatus === 'success' && meData) {
      setUser(meData)
      if (
        meData.subscription.plan &&
        meData.subscription.plan !== 'free' &&
        moment(meData.subscription.expires_at).isAfter(moment())
      ) {
        setValidSub(true)
      } else {
        setValidSub(false)
      }
    }
  }, [request, meStatus, meData])

  async function openPortal() {
    stripePortal({ customer_id: user!.stripe_customer_id! })
  }

  useEffect(() => {
    if (portalStatus === 'success' && portalData) {
      window.location.href = portalData.url
    }
  }, [portalStatus, portalData])

  const subscriptionCards = (products: SubscriptionProduct[]) => {
    const productsByDurationMonths = _.groupBy(
      _.sortBy(products, ['duration_month', 'name']),
      'duration_months'
    )
    return (
      <>
        {[1, 6].map((months) => (
          <Month key={months}>
            <MonthTitle>{months} mesi</MonthTitle>
            {productsByDurationMonths[months].map((product, i) => (
              <ProductCard
                key={i}
                title={product.name}
                disableButton={user?.subscription.plan !== 'free'}
                noBorderBottom={months === 6 && i === productsByDurationMonths[months].length - 1}
                description={product.description}
                price={`${Math.floor(product.price_cents / 100)},${String(
                  product.price_cents % 100
                ).padStart(2, '0')} ${product.currency.toUpperCase()} IVA esclusa`}
                onClick={() => history(`/checkout/${product.id}`)}
              />
            ))}
          </Month>
        ))}
      </>
    )
  }

  const oneshotCards = (products: OneshotProduct[]) => {
    return (
      <Month>
        {_.sortBy(products, 'talk_time_seconds').map((product, i) => (
          <ProductCard
            key={i}
            title={product.name}
            price={`${Math.floor(product.price_cents / 100)},${String(
              product.price_cents % 100
            ).padStart(2, '0')} ${product.currency.toUpperCase()} IVA esclusa`}
            noBorderBottom={i === products.length - 1}
            onClick={() => history(`/checkout/${product.id}`)} //, { avoidPlanModal: true }
          />
        ))}
      </Month>
    )
  }

  return (
    <Container>
      <PlanModal
        activatedPlan={activatedPlan ? String(activatedPlan) : undefined}
        onClose={() => history('/')}
      />
      <InfoBox>
        <PageTitle>Abbonamenti </PageTitle>
        {status !== 'success' && <SkeletonProductCard items={6} />}
        {data && subscriptionCards(data.subscription)}
        <Separetor />
        <PageTitle>Pacchetti aggiuntivi </PageTitle>
        {status !== 'success' && <SkeletonProductCard items={3} />}
        {data && oneshotCards(data.oneshot)}
        <div style={{ width: '100%', height: '50px' }}></div>
      </InfoBox>
      <CodeBox>
        {meStatus !== 'success' && <SkeletonBox />}
        {meStatus === 'success' && validSub && user && (
          <>
            <SubscriptionBox subscription={user?.subscription} />
            <SubManagmentButton
              text="Gestisci abbonamento"
              disabled={user.stripe_customer_id === null}
              radius={4}
              onClick={openPortal}
              color={user.stripe_customer_id === null ? 'gray' : 'primary'}
            />
          </>
        )}
        {meStatus === 'success' && !validSub && <WelfarCodeBox />}
      </CodeBox>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 50px;
  @media screen and (max-width: 860px) {
    flex-flow: column-reverse;
    width: calc(100% - 32px);
    padding: 0px 16px;
    padding-bottom: 50px;
  }
`

const Separetor = styled.div`
  width: 90%;
  height: 12px;
  background-color: #f7f7f7;

  @media screen and (max-width: 860px) {
    width: calc(100% + 32px);
    position: relative;
    left: -16px;
  }
`

const InfoBox = styled.div`
  width: 60%;
  @media screen and (max-width: 860px) {
    width: 100%;
  }
`

const MonthTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: 'Open sans', sans-serif;
  color: #5bb7d6;
`

const CodeBox = styled.div`
  width: 40%;
  margin-bottom: 40px;
  @media screen and (max-width: 860px) {
    width: 100%;
  }
`

const Month = styled.div`
  margin-bottom: 30px;
  width: 90%;
  @media screen and (max-width: 860px) {
    width: 100%;
  }
`

const PageTitle = styled.p`
  font-family: 'Open sans', sans-serif;
  font-size: 20px;
  font-weight: 800;
  color: #3b7b95;
`

const SubManagmentButton = styled(Button)`
  width: 260px;
  margin-left: auto;
  margin-top: 40px;
  @media screen and (max-width: 860px) {
    margin-right: auto;
  }
`
