import React, { useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { Button, Radios } from '@duckma/react-ds'

import { ControlledField, ControlledForm } from '../../components/ControlledForm'
import { useRequest } from '../../hooks/useRequest'
import { api, CreateUser as TCreateUser } from '../../data/api'
import { requiredValidator, emailValidator } from '../../utils/validators'
import { useDirty } from '../../hooks/useDirty'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { PasswordField } from '../../components/PasswordField'

const roleChoices = [
  { key: 'admin', label: 'Admin' },
  { key: 'operator', label: 'Operatore centralino' },
]

export const CreateUser = () => {
  const [request, { status }] = useRequest(api.createUser)

  const [role, setRole] = useState('admin')
  const [values, setValues] = useState<Partial<TCreateUser>>({ password: '' })
  const [valid, setValid] = useState(true)
  const [passwordValid, setPasswordValid] = useState(false)

  const initialValues = useMemo(() => ({}), [])
  const onChange = useCallback((values: {}, valid: boolean) => {
    setValues((v) => ({ ...v, ...values }))
    setValid(valid)
  }, [])
  const onPasswordChange = useCallback((password: string, valid: boolean) => {
    setValues((v) => ({ ...v, password }))
    setPasswordValid(valid)
  }, [])

  useDirty(values, {})
  useSuccessToast(status, 'Amministratore aggiunto con successo', '/users')

  return (
    <Container>
      <Radios left choices={roleChoices} value={role} onChange={setRole} />
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault()
          request({
            ...values,
            role,
          } as NonNullable<TCreateUser>)
        }}
        autoComplete="off"
      >
        <FieldsContainer>
          <ControlledForm initialValues={initialValues} onChange={onChange}>
            <ControlledField name="nome" fieldName="first_name" validator={requiredValidator()} />
            <ControlledField name="cognome" fieldName="last_name" validator={requiredValidator()} />
            <PasswordField value={values.password ?? ''} onChange={onPasswordChange} />
            <ControlledField
              name="indirizzo-email"
              fieldName="email"
              validator={emailValidator()}
            />
          </ControlledForm>
        </FieldsContainer>
        <div style={{ flexGrow: 1 }} />
        <FooterContainer>
          <div style={{ flexGrow: 1 }} />
          <Button
            text="Salva"
            radius={4}
            disabled={!valid ?? !passwordValid}
            loading={status === 'loading'}
            style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
          />
        </FooterContainer>
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: calc(100% - 20px);
  margin-left: 54px;
  margin-right: 54px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 55px;
`

const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
`

const FooterContainer = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  margin-bottom: 66px;
`
